<template>
  <el-row class="signup">
    <el-card shadow="never">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        class="demo-ruleForm"
      >
        <el-col :span="24">
          <el-form-item>
            <h2>User ID登録</h2>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="" prop="user_name">
            <el-input
              type="text"
              v-model="ruleForm.user_name"
              autocomplete="off"
              placeholder="User ID"
              :disabled="this.status"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="" prop="full_name">
            <el-input
              type="text"
              v-model="ruleForm.full_name"
              autocomplete="off"
              placeholder="Full Name"
              :disabled="this.status"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="" prop="pass_word">
            <el-input
              type="password"
              v-model="ruleForm.pass_word"
              autocomplete="off"
              placeholder="Password"
              :disabled="this.status"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="" prop="email">
            <el-input
              type="email"
              v-model="ruleForm.email"
              autocomplete="off"
              placeholder="Email Address"
              :disabled="this.status"
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24" v-if="this.status">
          <el-form-item label="" prop="rand_num">
            <el-input
              type="password"
              v-model="ruleForm.rand_num"
              autocomplete="off"
              placeholder="BRSコード"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item v-if="!this.status">
            <el-button type="primary" @click="submitForm('ruleForm')">
              <span>登録</span>
            </el-button>
          </el-form-item>
          <el-form-item v-else>
            <el-button type="primary" @click="submitFormRandNum('ruleForm')">
              <span>送信</span>
            </el-button>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item>
            <h5>
              既にUser IDをお持ちですか?
              <el-link href="/#/signin" :underline="false" type="primary"
                >サインイン</el-link
              >
              へ
            </h5>
          </el-form-item>
        </el-col>
      </el-form>
    </el-card>
  </el-row>
</template>

<script>
import { Signup, Active } from "api/user";
import { Message } from "element-ui";

export default {
  name: "SignUp",
  data() {
    var validateUserName = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("User IDを入力してください。"));
        // 记住喽获取input字符的长度用value.length
      } else if (value.length < 6 || value.lengt > 30) {
        console.log(value.lengt);
        callback(new Error("User IDを6桁〜30桁にしてください。"));
      } else {
        callback();
      }
    };
    return {
      status: false,
      ruleForm: {
        user_name: "",
        full_name: "",
        email: "",
        pass_word: "",
        rand_num: "",
        res: ""
      },
      is_active: this.$store.getters.getActive,
      rules: {
        // 但规则不够用的时候就自己写函数
        user_name: [
          { validator: validateUserName, trigger: "blur" }
          // {required: true, message: 'User ID is required.', trigger: 'blur'},
          // {minlength: 6, message: 'Use 6 to 30 letters & digits only.', trigger: 'blur'}
        ],
        full_name: [
          {
            required: true,
            message: "Full Nameを入力してください。",
            trigger: "blur"
          }
        ],
        email: [
          {
            required: true,
            message: "EmailAddressを入力してください。",
            trigger: "blur"
          },
          {
            type: "email",
            message: "EmailAddressを正しく入力してください。",
            trigger: ["blur", "change"]
          }
        ],
        pass_word: [
          {
            required: true,
            message: "Passwordを入力してください。",
            trigger: "blur"
          }
        ]
      }
    };
  },
  watch() {
    {
      this.$router.push({ path: "SignIn" });
    }
    true;
  },
  methods: {
    Signup() {
      let values = {};
      values["user_name"] = this.ruleForm.user_name;
      values["full_name"] = this.ruleForm.full_name;
      values["email"] = this.ruleForm.email;
      values["pass_word"] = this.ruleForm.pass_word;
      Signup(values)
        .then(res => {
          if (res.data.status === 1) {
            this.status = true;
            // this.$router.push('SignIn')
            Message({
              showClose: true,
              message: res.data.message,
              type: "success"
            });
          } else {
            Message({
              showClose: true,
              message: res.data.message,
              type: "error"
            });
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    Active() {
      let values = {};
      values["email"] = this.ruleForm.email;
      values["rand_num"] = this.ruleForm.rand_num;
      Active(values)
        .then(res => {
          if (res.data.status === 1) {
            clearTimeout(this.timer); //清除延迟执行
            this.timer = setTimeout(() => {
              this.$router.push({ path: "Signin" });
            }, 3000);

            Message({
              showClose: true,
              message: res.data.message,
              type: "success"
            });
          } else {
            Message({
              showClose: true,
              message: res.data.message,
              type: "error"
            });
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    submitForm(ruleForm) {
      this.$refs[ruleForm].validate(valid => {
        if (valid) {
          this.Signup();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    submitFormRandNum(ruleForm) {
      this.$refs[ruleForm].validate(valid => {
        if (valid) {
          this.Active();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    }
  }
};
</script>
<style scoped>
.el-button {
  width: 100%;
}

.signup {
  margin: 0;
  padding: 5% 20% 0 58%;
  background-image: url("../../assets/img/5626873.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100%;
  width: 100%;
}
</style>
